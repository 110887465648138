<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#7CB9E8" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-img src="./../../assets/images/login.jpg">
      <v-layout wrap justify-center align-center style="height: 100vh">
        <v-flex xs10 sm6 md5 lg4 align-self-center>
          <v-card rounded="xl" elevation="24">
            <v-layout wrap justify-center pt-10>
              <v-flex xs7 sm5 md4 lg5 xl3>
                <router-link to="/">
                  <v-layout wrap justify-center>
                    <v-flex xs1><v-img src="./../../assets/newecologo.png" height="25px" width="25px"></v-img></v-flex>
                    <v-flex xs8 sm10 md10 lg8 xl8>
                      <span style="font-family: pollerOneRegular; font-size: 14px; color: #000000">ECOLedger</span>
                    </v-flex>
                  </v-layout>
                </router-link>

              </v-flex>
            </v-layout>
            <v-layout wrap justify-center py-5>
              <v-flex xs12 py-3>
                <span style="font-family: montserratBold; font-size: 20px">Login
                </span>
              </v-flex>
              <v-flex xs12>
                <span style="font-family: montserratBold; font-size: 20px">Welcome Back
                </span>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center pa-5>
              <v-flex xs10 sm10 text-left>
                <span style="font-family: montserratRegular; font-size: 14px">Email Address</span>
                <v-text-field outlined dense autofocus v-model="user.email" placeholder="Email"></v-text-field>
                <span style="font-family: montserratRegular; font-size: 14px">Password</span>
                <v-text-field outlined dense v-model="user.password" placeholder="Password "
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :type="show1 ? 'text' : 'password'"
                  @click:append="show1 = !show1" @keyup.enter="login()"></v-text-field>
                <router-link to="/forgot">
                  <span style="font-family: montserratSemiBold; font-size: 14px;color:#6AAFEE">Forgot Password?</span>
                </router-link>
              </v-flex>
              <v-flex xs10 py-3>
                <v-btn rounded block color="#7CB9E8" dark @click="login()">
                  <span style="font-size: 12px; font-family: montserratSemiBold">Log in</span>
                </v-btn>
              </v-flex>
              <v-flex xs10 py-3>
                <span style="font-family: montserratRegular; font-size:14px">Or </span>
                <router-link to="/register">
                  <span style="font-family: montserratBold; font-size:14px; color:#6AAFEE">Register</span>
                </router-link>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-img>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      show1: false,
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      msg: null,
      timeout: 5000,
      idproofpic: "",
      document: "",
      documentImg: "",
      currentImage: null,
      newProfilepic: false,
      documentSts: "",
      newIdproof: false,
      checkbox: true,
      user: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    login() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/user/signin",
        data: this.user,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            if (response.data.status) {
              localStorage.setItem("token", response.data.token);
              // this.$store.commit("userData", response.data.userdata);
              this.$store.commit("loginUser", response.data.status);
              this.$store.commit("userType", response.data.role);
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.$router.push("/");
              location.reload()
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    delProPic() {
      //   this.idProof = "";
      //   this.proPic = "";
      (this.currentImage = null), (this.newProfilepic = false);
      // this.profilepic = "";
    },
    uploadDocuments(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      if (files[0].size > 5097152) {
        // this.msg = "File Should be less than 2MB";
        // this.showSnackBar = true;
        return;
      }
      this.filename = e.target.files[0].name;
      this.document = e.target.files[0];
      var reader = new FileReader();
      reader.onload = (e) => {
        this.documentImg = e.target.result;
      };
      this.documentSts = true;
      this.newIdproof = true;
      reader.readAsDataURL(files[0]);
    },
  },
};
</script>
<style>